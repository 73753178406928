import {
  GET_ERRORS,
  SET_CURRENT_ARTIST,
  LOGOUT,
  UPDATE_HEADER_TITLE,
} from "./types";

import AuthService from "../services/authService";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import store from "../store";
import { useTranslation } from "react-i18next";

// Login - Get Artist Token
export const loginArtist = (email, password) => (dispatch) => {
  
  return AuthService.loginArtist(email, password)
    .then((res) => {
      console.log("inside POST http://localhost:8082/artist/api/authenticate");
      console.log("res.data", res);

      // Save to localStorage
      const token = res.artist.token;
      // console.log("token", res.data.admin.token);

      // Set token to ls
      localStorage.setItem("jwtToken", token);

      // Set token to Auth header
      // TODO: I believe this should be changed
      setAuthToken(token);

      // Decode token to get artist data
      // ! we don't need this varible because jwt tokne has only artist id as data

      const decoded = jwt_decode(token);
      console.log("decoded token", JSON.stringify(decoded));

      // Set current artsit from the api response
      dispatch(setCurrentArtist(res.artist));

      // traduction module
      const { t } = useTranslation();
      store.dispatch(updateHeaderTitle(0));
    })
    .catch((err) => {
      console.log("inside err");
      console.log(err);
      console.log("err.response.data");
      console.log(err.response.data);
      console.log("-----------------");
      dispatch({
        type: GET_ERRORS,
        // TODO: Fix the error object that is sent in the backend
        // The error that is sent should already be in the format of an object
        payload: { errors: err.response },
        // payload: err.response.data
      });
    });
};

// Set logged in artist
export const setCurrentArtist = (decoded) => {
  return {
    type: SET_CURRENT_ARTIST,
    payload: decoded,
  };
};

export const updateHeaderTitle = (title) => {
  return {
    type: UPDATE_HEADER_TITLE,
    title,
  };
};

// Log artist out
export const logoutArtist = () => (dispatch) => {
  // Remove token from localStorage
  try {
    console.log("---------------------------->In LogoutArtist");
    localStorage.removeItem("jwtToken");
    // Remove auth header for future requests
    setAuthToken(false);
    //to refresh css fucntion
    window.location.href='/'
    // Set current artsit to {} which will set isAuthenticated to false
    dispatch(setCurrentArtist({}));
    dispatch(updateHeaderTitle({}));
    dispatch({
      type: LOGOUT,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};



