import { GET_ERRORS, SET_CURRENT_ADMIN, LOGOUT ,UPDATE_HEADER_TITLE, } from "./types";

import AuthService from "../services/authService";
import setAuthAdminToken from "../utils/setAuthAdminToken";
import jwt_decode from "jwt-decode";
import store from "../store";

export const loginAdmin = (email, password) => (dispatch) => {
  return AuthService.loginAdmin(email, password)
    .then((res) => {
      console.log("res.data", res);

      const token = res.user.token;

      localStorage.setItem("adminJwtToken", token);

      setAuthAdminToken(token);

      const decoded = jwt_decode(token);
      console.log("decoded token", JSON.stringify(decoded));

      dispatch(setCurrentAdmin(res.user));
      store.dispatch(updateHeaderTitle(6));
    })
    .catch((err) => {
      console.log("inside err");
      console.log(err);
      console.log("err.response.data");
      console.log(err.response.data);
      console.log("-----------------");
      dispatch({
        type: GET_ERRORS,

        payload: { errors: err.response },
      });
    });
};

export const setCurrentAdmin = (decoded) => {
  return {
    type: SET_CURRENT_ADMIN,
    payload: decoded,
  };
};

export const logoutAdmin = () => (dispatch) => {
  try {
    console.log("---------------------------->In Logoutadmin");
    localStorage.removeItem("adminJwtToken");

    setAuthAdminToken(false);
    window.location.href='/'

    dispatch(setCurrentAdmin({}));
    dispatch(updateHeaderTitle({}));
    dispatch({
      type: LOGOUT,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};


export const updateHeaderTitle = (title) => {
  return {
    type: UPDATE_HEADER_TITLE,
    title,
  };
};